"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
exports.__esModule = true;
exports.GoogleSnippet = exports.usePageview = exports.pageview = exports.gaEvent = exports.track = void 0;
var lodash_1 = require("lodash");
var ga_1 = require("./ga");
exports.pageview = ga_1.pageview;
exports.gaEvent = ga_1.event;
exports.GoogleSnippet = ga_1.GoogleSnippet;
var use_pageview_1 = require("./use-pageview");
exports.usePageview = use_pageview_1.usePageview;
var DEBUG_ANALYTICS = false;
var track = function (event, paramsOrCallback, callback) {
    return new Promise(function (resolve) { return __awaiter(void 0, void 0, void 0, function () {
        function politelyExit() {
            DEBUG_ANALYTICS && console.debug("TRACKED: ".concat(event));
            if ((0, lodash_1.isFunction)(callback) && !wasCalled) {
                wasCalled = true;
                callback.apply(null, [event, wasCalled]);
            }
            resolve(true);
        }
        var ahoy, wasCalled, params, timeout, store;
        return __generator(this, function (_a) {
            ahoy = window.ahoy;
            wasCalled = false;
            params = (0, lodash_1.isFunction)(paramsOrCallback) ? {} : paramsOrCallback;
            timeout = 1250;
            if ((0, lodash_1.isUndefined)(callback) && (0, lodash_1.isFunction)(paramsOrCallback)) {
                callback = paramsOrCallback;
            }
            store = console.error;
            console.error = function () { };
            setTimeout(politelyExit, timeout);
            console.error = store;
            if (ahoy && (0, lodash_1.isFunction)(ahoy.track)) {
                ahoy.track(event, params);
            }
            if (window.fbq) {
                window.fbq('trackCustom', event, params);
            }
            (0, ga_1.event)({ action: event, params: params });
            if (window.ga) {
                window.ga('send', {
                    hitType: 'event',
                    eventAction: event
                });
            }
            politelyExit();
            return [2 /*return*/];
        });
    }); });
};
exports.track = track;
