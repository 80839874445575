"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
exports.__esModule = true;
exports.SubscribeToConvertkitForm = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var React = __importStar(require("react"));
var components_1 = require("@skillrecordings/react/dist/components");
var use_convertkit_form_1 = require("../../hooks/use-convertkit-form");
/**
 * This form posts to a designated api URL (assumes `/api/convertkit/subscribe
 * by default`)
 *
 * Styling is handled by css! In the following example we utilize Tailwind and `@apply`
 *
 * @example
 * ```css
 * [data-sr-convertkit-subscribe-form] {
 *     @apply flex flex-col w-full max-w-[340px] mx-auto;
 *     [data-sr-input] {
 *         @apply block mb-4 w-full px-4 py-3 border placeholder-opacity-60 bg-opacity-50 rounded-lg shadow sm:text-base sm:leading-6;
 *     }
 *     [data-sr-input-label] {
 *         @apply font-medium pb-1 inline-block;
 *     }
 *     [data-sr-input-asterisk] {
 *         @apply opacity-50;
 *     }
 *     [data-sr-button] {
 *         @apply pt-4 pb-5 mt-4 flex items-center justify-center rounded-lg text-black bg-yellow-500 hover:bg-opacity-100 transition font-bold text-lg focus-visible:ring-yellow-200 hover:scale-105 hover:-rotate-1 hover:bg-yellow-400;
 *     }
 * }
 *```
 * @param formId the Convertkit form id, defaults to `process.env.NEXT_PUBLIC_CONVERTKIT_SIGNUP_FORM`
 * @param submitButtonElem an element to use as the button for the form submit
 * @param errorMessage A string or element representing the message shown on error
 * @param successMessage A string or element representing the message shown on success
 * @param actionLabel Label for the button (not used if submitButtonElem is used)
 * @param onError function to call on error
 * @param onSuccess function to call on success
 * @param subscribeApiURL optional param to override the api url that gets posted to
 * @param fields custom subscriber fields to create or update
 * @param rest anything else!
 * @constructor
 */
var SubscribeToConvertkitForm = function (_a) {
    var formId = _a.formId, submitButtonElem = _a.submitButtonElem, _b = _a.errorMessage, errorMessage = _b === void 0 ? (0, jsx_runtime_1.jsx)("p", { children: "Something went wrong." }) : _b, _c = _a.successMessage, successMessage = _c === void 0 ? (0, jsx_runtime_1.jsx)("p", { children: "Thanks!" }) : _c, _d = _a.actionLabel, actionLabel = _d === void 0 ? 'Subscribe' : _d, _e = _a.onError, onError = _e === void 0 ? function () { } : _e, _f = _a.onSuccess, onSuccess = _f === void 0 ? function () { } : _f, subscribeApiURL = _a.subscribeApiURL, id = _a.id, fields = _a.fields, rest = __rest(_a, ["formId", "submitButtonElem", "errorMessage", "successMessage", "actionLabel", "onError", "onSuccess", "subscribeApiURL", "id", "fields"]);
    var _g = (0, use_convertkit_form_1.useConvertkitForm)({
        formId: formId,
        onSuccess: onSuccess,
        onError: onError,
        fields: fields,
        submitUrl: subscribeApiURL
    }), isSubmitting = _g.isSubmitting, status = _g.status, handleChange = _g.handleChange, handleSubmit = _g.handleSubmit;
    return ((0, jsx_runtime_1.jsxs)("form", __assign({ "data-sr-convertkit-subscribe-form": status, onSubmit: handleSubmit }, rest, { children: [(0, jsx_runtime_1.jsx)(components_1.Input, { label: "First Name", name: "first_name", id: id ? "first_name_".concat(id) : 'first_name', onChange: handleChange, placeholder: "Preferred name", type: "text" }), (0, jsx_runtime_1.jsx)(components_1.Input, { label: "Email", name: "email", id: id ? "email_".concat(id) : 'email', onChange: handleChange, placeholder: "you@example.com", type: "email", required: true }), submitButtonElem ? (React.cloneElement(submitButtonElem, {
                isLoading: isSubmitting,
                type: 'submit'
            })) : ((0, jsx_runtime_1.jsx)(components_1.Button, __assign({ isLoading: isSubmitting, type: "submit" }, { children: actionLabel }))), status === 'success' &&
                (React.isValidElement(successMessage) ? (successMessage) : ((0, jsx_runtime_1.jsx)("p", { children: successMessage }))), status === 'error' &&
                (React.isValidElement(errorMessage) ? (errorMessage) : ((0, jsx_runtime_1.jsx)("p", { children: errorMessage })))] })));
};
exports.SubscribeToConvertkitForm = SubscribeToConvertkitForm;
exports["default"] = exports.SubscribeToConvertkitForm;
