"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
exports.__esModule = true;
exports.GoogleSnippet = exports.event = exports.pageview = void 0;
var React = __importStar(require("react"));
var pageview = function (url) {
    if (window.gtag && process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
        window.gtag('config', process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
            page_path: url
        });
    }
};
exports.pageview = pageview;
// log specific events happening.
var event = function (_a) {
    var action = _a.action, params = _a.params;
    if (window.gtag && process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) {
        window.gtag('event', action, params);
    }
};
exports.event = event;
var GoogleSnippet = function () {
    return process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS ? (React.createElement(React.Fragment, null,
        React.createElement("script", { async: true, src: "https://www.googletagmanager.com/gtag/js?id=".concat(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS) }),
        React.createElement("script", { dangerouslySetInnerHTML: {
                __html: "\n            window.dataLayer = window.dataLayer || [];\n            function gtag(){dataLayer.push(arguments);}\n            gtag('js', new Date());\n            gtag('config', '".concat(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, "', {\n              page_path: window.location.pathname,\n            });\n          ")
            } }))) : null;
};
exports.GoogleSnippet = GoogleSnippet;
