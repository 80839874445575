"use strict";
exports.__esModule = true;
exports.VIEWING_AS_USER_KEY = exports.EXPIRES_AT_KEY = exports.ACCESS_TOKEN_KEY = exports.USER_KEY = exports.CONVERTKIT_SUBSCRIBE_API_URL = exports.CONVERTKIT_SIGNUP_FORM = exports.CONVERTKIT_TOKEN = exports.CONVERTKIT_API_SECRET = exports.CONVERTKIT_BASE_URL = exports.CK_SUBSCRIBER_KEY = exports.AUTH_REDIRECT_URL = exports.AUTH_CLIENT_ID = exports.AUTH_DOMAIN = void 0;
exports.AUTH_DOMAIN = process.env.NEXT_PUBLIC_AUTH_DOMAIN;
exports.AUTH_CLIENT_ID = process.env.NEXT_PUBLIC_CLIENT_ID;
exports.AUTH_REDIRECT_URL = process.env.NEXT_PUBLIC_REDIRECT_URI;
exports.CK_SUBSCRIBER_KEY = process.env.NEXT_PUBLIC_CONVERTKIT_SUBSCRIBER_KEY || 'ck_subscriber_id';
exports.CONVERTKIT_BASE_URL = process.env.CONVERTKIT_BASE_URL || 'https://api.convertkit.com/v3/';
exports.CONVERTKIT_API_SECRET = process.env.CONVERTKIT_API_SECRET;
exports.CONVERTKIT_TOKEN = process.env.NEXT_PUBLIC_CONVERTKIT_TOKEN;
exports.CONVERTKIT_SIGNUP_FORM = process.env.NEXT_PUBLIC_CONVERTKIT_SIGNUP_FORM;
exports.CONVERTKIT_SUBSCRIBE_API_URL = process.env.NEXT_PUBLIC_CONVERTKIT_SUBSCRIBE_URL ||
    '/api/convertkit/subscribe';
// TODO: create unique keys for site authorization
exports.USER_KEY = process.env.NEXT_PUBLIC_USER_KEY || 'user';
exports.ACCESS_TOKEN_KEY = process.env.NEXT_PUBLIC_ACCESS_TOKEN_KEY || 'access_token';
exports.EXPIRES_AT_KEY = process.env.NEXT_PUBLIC_EXPIRES_AT_KEY || 'expires_at';
exports.VIEWING_AS_USER_KEY = process.env.NEXT_PUBLIC_VIEWING_AS_USER_KEY || 'viewing_as_user';
